// src/reducer.js
import * as actionTypes from './actionTypes';

const initialState = {
  allData: [],
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.SET_NEW_DENOMINATION:
      state = {
        ...state,
        allData: action.payload,
      };
      break;
    case actionTypes.ADD_NEW_DENOMINATION:
      // console.log("ADD_NEW_DENOMINATION : ", action.payload)
      state = {
        ...state,
        allData: [...state.allData, action.payload],
      };
      break;
    case actionTypes.REMOVE_AN_DENOMINATION:
      // console.log("REMOVE_AN_DENOMINATION : ", action.payload)
      state = {
        ...state,
        allData: state.allData.filter(
          (denomination) => denomination.AMOUNT !== action.payload.AMOUNT
        ),
      };
      break;
    case actionTypes.UPDATE_QUANTITY_DENOMINATION:
      state = {
        ...state,
        allData: state.allData.map((denomination) =>
          denomination.AMOUNT === action.payload.AMOUNT
            ? { ...denomination, QUANTITY: action.payload.QUANTITY, TOTAL: parseInt(action.payload.AMOUNT) * parseInt(action.payload.QUANTITY) }
            : denomination
        ),
      };
      break;
    case actionTypes.WIPE_OUT_DENOMINATION:
      state = { allData: [] }
      break;
    default:
      state = { ...state };
  }
  return state;
};

export default reducer;

import React, { Suspense} from "react";
import "./App.css";
import { router } from "./routes";
import AllProductsFetch from "./layouts/innerComponent/AllProducts/index";
import FetchCategory from "./layouts/innerComponent/NavarCategories/fetchCategory";
import BestSeller from "./store/bestSeller";
import FeaturedProducts from "./store/featuredProducts";
import TopFiveProducts from "./store/topFive";
import EntertainmentProducts from "./store/EntertainmentProducts";
import SliderData from "./store/fetchSlider";
import { AuthProvider } from "./hooks/contexts/AuthContext";
import { RouterProvider } from "react-router-dom";
import PageLoading from "./PageLoading";
import { useMediaQuery } from "@mui/system";
import MobileRedirect from "./components/MobileRedirect/MobileRedirect";

function App() {
  const device = useMediaQuery('(max-width:600px)');

  if (device) {
    return (<MobileRedirect />);
  } else {
    return (
      <React.Fragment>
        <AllProductsFetch />
        <BestSeller />
        <FeaturedProducts />
        <TopFiveProducts />
        <EntertainmentProducts />
        <SliderData />
        <FetchCategory />

        <AuthProvider>
          <Suspense fallback={<PageLoading />}>
            <RouterProvider router={router} />
        </Suspense>
      </AuthProvider>

      </React.Fragment >
    );
  }
}

export default App;

import React, { lazy } from "react";
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

// Redirect to app store
const MobileRedirect = lazy(() => import("../components/MobileRedirect/MobileRedirect"));

// Lazy-load components for public routes
const ProfileLayout = lazy(() => import("../layouts/ProfileLayout/"));
const SingleProduct = lazy(() => import("../pages/ALLGIFTCARD/SMALLDEVICE/SingleProduct"));
const BlogDetail = lazy(() => import("../pages/BLOGDETAILS"));
const CorpPage = lazy(() => import("../pages/CORP"));
const PageNotFound = lazy(() => import("../pages/OTHERPAGE/PageNotFound"));
const Thankyou = lazy(() => import("../pages/OTHERPAGE/Thankyou"));
const Layouts = lazy(() => import("../layouts"));
const AuthenticateUser = lazy(() => import("./AuthticateUser"));

// Lazy-load components for user routes
const HomePage = lazy(() => import("../pages/DASHBOARD/Homepage"));
const AllGiftCardPage = lazy(() => import("../pages/ALLGIFTCARD"));
const ProductDetailpage = lazy(() => import("../pages/PRODUCTDETAILS"));
const AboutPage = lazy(() => import("../pages/ABOUTUS"));
const ContactPage = lazy(() => import("../pages/CONTACTUS"));
const LoginPage = lazy(() => import("../pages/LOGIN_SIGNUP"));
const BlogPage = lazy(() => import("../pages/BLOGS"));
const GiftCardWorks = lazy(() => import("../pages/GIFTCARDSWORKS"));
const PrivacyPolicy = lazy(() => import("../pages/PRIVACYPOLICY"));
const CorpPolicy = lazy(() => import("../pages/CORPPOLICY"));
const TermsAndCondition = lazy(() => import("../pages/TERMSANDCONDITION"));

// Lazy-load components for admin routes
const Statement = lazy(() => import("../pages/profileDashboard/statement"));
const UserProfile = lazy(() => import("../pages/profileDashboard/user"));
const Analytics = lazy(() => import("../pages/profileDashboard/analytics"));
const Order = lazy(() => import("../pages/profileDashboard/order"));
const Notification = lazy(() => import("../pages/profileDashboard/notification"));
const ChangePassword = lazy(() => import("../pages/profileDashboard/changePassword"));
const ViewCards = lazy(() => import("../pages/profileDashboard/viewCard"));

const basename = process.env.REACT_APP_ENDPOINT || '/';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route path="/" element={<Layouts />}>
        <Route index element={<HomePage />} />
        <Route path="card-list/:category?/*?" element={<AllGiftCardPage />} />
        <Route path="card-details/:id/:title?" element={<ProductDetailpage />} />
        <Route path="aboutus" element={<AboutPage />} />
        <Route path="contactus" element={<ContactPage />} />
        <Route path="blog" element={<BlogPage />} />
        <Route path="blogs/:title" element={<BlogDetail />} />

        <Route path="how-gift-card-work" element={<GiftCardWorks />} />
        <Route path="corppolicy" element={<CorpPolicy />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="termsandcondition" element={<TermsAndCondition />} />
        <Route path="category/:title" element={<SingleProduct />} />
        <Route path="corporates" element={<CorpPage />} />
        <Route path="thank-you" element={<Thankyou />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path="/" element={<AuthenticateUser />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<LoginPage />} />
      </Route>

      <Route path="profile" element={<ProfileLayout />}>
        <Route path="statement" element={<Statement />} />
        <Route path="user-profile" element={<UserProfile />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="notifications" element={<Notification />} />
        <Route path="password-change" element={<ChangePassword />} />
        <Route path="order">
          <Route index element={<Order />} />
          <Route path=":orderId" element={<ViewCards />} />
        </Route>
        <Route path="*" element={<Navigate to="/profile/statement" replace />} />
      </Route>

      <Route path="mobile-redirect" element={<MobileRedirect />} />

    </React.Fragment>
  ), { basename }
);
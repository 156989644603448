import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider(props) {
    // const [authUser, setAuthUser] = useState(null);
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [authUser, setAuthUser] = useState(() => {
        const storedUser = localStorage.getItem('99GIFT');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const [isLoggedIn, setIsLoggedIn] = useState(() => !!localStorage.getItem('99GIFT'));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay and authentication check
        const timer = setTimeout(() => {
            const storedUser = localStorage.getItem('99GIFT');
            if (storedUser) {
                setAuthUser(JSON.parse(storedUser));
                setIsLoggedIn(true);
            } else {
                setAuthUser(null);
                setIsLoggedIn(false);
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const value = {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    }
    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    );
}
import { Box, Container, Grid, Stack, Typography } from "../../imports/muiImport";
import { LogoLowHeight, googlePlayStoreIcon, appleAppStore, } from "../../imports/generalImport";
import GooglePlay from "../../assets/others/app-store/google-play.png"
import AppleStore from "../../assets/others/app-store/apple-store.png"

function MobileRedirect() {
    return (
        <Container disableGutters={true} >
            <Grid container sx={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                minHeight: "100vh",
                backgroundColor: "primaryDark.main",
                pt: 15,
            }}>
                {/* Logo */}
                <Grid item>
                    <Box
                        component='img'
                        src={LogoLowHeight}
                        width={170}
                        sx={{
                            border: 1,
                            borderRadius: 2,
                            boxShadow: 6,
                        }} />
                </Grid>

                <Grid item xs={10} sx={{
                    minHeight: 220,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center"
                }}>

                    <Typography
                        component="span"
                        sx={{
                            fontSize: "26px",
                            fontWeight: 600,
                            textTransform: "uppercase",
                            color: "white.main",
                            display: "inline",
                            wordSpacing: 2,
                            mb: 2,
                            textAlign: "center"
                        }}>
                        <Typography
                            component="span"
                            sx={{
                                fontSize: "26px",
                                fontWeight: 600,
                                textTransform: "uppercase",
                                color: "secondary.main",
                                display: "inline-block",
                                wordSpacing: 1,
                                mr: 1,
                            }}>
                            Gifts
                        </Typography>
                        speak Louder Than Words
                    </Typography>

                    <Typography sx={{
                        fontSize: "10px",
                        fontWeight: 400,
                        textTransform: "uppercase",
                        color: "white.main",
                        textAlign: "center"
                    }}>
                        Download our App To Start sending on the move available on Android / app Store
                    </Typography>
                </Grid>

                <Grid item sx={{ mb: 2, }}>
                    <a href='https://play.google.com/store/apps/details?id=in.nngift.vouchers'
                        rel="noreferrer"
                        target='_blank'
                        style={{ textDecoration: "none" }}
                    >
                        <Box component='img' src={GooglePlay} sx={{
                            width: 167,
                            borderRadius: 3,
                            "&: hover": {
                                cursor: "pointer"
                            }
                        }} />
                    </a>
                </Grid>
                <Grid item sx={{ mb: 2, }}>
                    <a href='https://apps.apple.com/in/app/99gift/id1645057090'
                        target='_blank'
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                    >
                        <Box component='img' src={AppleStore} sx={{
                            width: 167,
                            borderRadius: 3,
                            "&: hover": {
                                cursor: "pointer"
                            }
                        }} />
                    </a>
                </Grid>

            </Grid>
        </Container >
    )
}

export default MobileRedirect